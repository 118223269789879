<template>
  <FullScreenDialog
    v-model="serviceDialog"
    title="Servizi"
    :routeFather="pathToGoBack"
    :routeFatherName="pathName"
    :otherGoBackParams="{ params: { filters: this.filters } }"
  >
    <template v-slot:toolbar-extension>
      <v-tabs v-model="tab" align-with-title>
        <v-tabs-slider color="primary"></v-tabs-slider>
        <v-tab>Generale</v-tab>
      </v-tabs>
    </template>

    <template v-slot:content>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-card flat>
            <v-card-text class="pa-0">
              <GeneralForm mode="edit" view-phases-list @edit-phases="openEditPhases"></GeneralForm>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </template>

    <template v-slot:footer-actions>
      <div>
        <v-spacer></v-spacer>
        <v-btn
          text
          color="error"
          @click="close"
        >Annulla</v-btn>
        <v-btn
          text
          color="default"
          :disabled="!formValid || isLicenseBlocked"
          @click="submitForm"
        >Salva</v-btn>
      </div>
    </template>

    <template v-slot:header-actions>
      <StandardDialog 
        v-model="editPhasesDialog" 
        :dialog-height="null" 
        title="Modifica fasi" 
        close-button-text="Chiudi"
        dialog-max-width="700px"
      >
        <PhasesForm 
          v-model="phases"
          @move-phase="movePhase" 
          @update-phase="updatePhase"
          @create-phase="createPhase"
          @remove-phase="removePhase"
        ></PhasesForm>
      </StandardDialog>
    </template>
  </FullScreenDialog>
</template>

<script>
import FullScreenDialog from "@/components/common/FullScreenDialog.vue";
import StandardMenu from '@/components/common/StandardMenu.vue';
import StandardDialog from '@/components/common/StandardDialog.vue';
import GeneralForm from "@/components/areas/registries/services/form/GeneralFormTab.vue"
import PhasesForm from "@/components/areas/registries/services/phases/PhasesForm.vue"
import servicesService from '@/services/services/services.service.js'
import servicePhasesService from '@/services/servicePhases/servicePhases.service.js'
import serviceForm from '@/services/services/service.form.js'

export default {
  name: "ServicesRegistryEditForm",
  components: {
    FullScreenDialog,
    StandardMenu,
    GeneralForm,
    PhasesForm,
    StandardDialog
  },
  data: function () {
    return {
      id: undefined,
      tab: null,
      formValid: false,
      loadingService: false,
      serviceDialog: this.openDialog,
      editPhasesDialog: false,
      servicesTab: [
        {
          key: 0,
          title: "Generale",
          name: "general",
          component: "GeneralForm",
        }
      ],
      service: undefined,
      phases: [],
      originalUrl: window.location.pathname,
      isLicenseBlocked: undefined
    };
  },
  mounted() {
    this.changeTabBasedOnProp(this.tabName)

    this.id = this.$route.params.id
    if(this.id) {
      this.fetchService()
    }


    let self = this
    serviceForm.on('update', function(data) {
      self.service = data.service
    })

    self.valid = serviceForm.valid
    serviceForm.on('valid-change', function(valid) {
      self.formValid = valid
    })
  },
  props: {
    openDialog: {
      type: Boolean,
      default: false,
    },
    pathToGoBack: {
      type: String,
      default: "/registries",
    },
    pathName: {
      type: String,
    },
    tabName: {
      type: String,
      default: 'registry'
    },
    filters: {
      type: Array,
    }
  },
  methods: {
    changeTabBasedOnProp(tabName) {
      for(let i = 0; i < this.servicesTab.length; i++) {
        if(this.servicesTab[i].name == tabName) {
          this.tab = i
          break
        }
      }
    },
    submitForm() {
      servicesService.update(this.service).then((result) => {
        this.close()
      })
    },
    close() {
      if(!!this.pathName)
        this.$router.push({name: this.pathName, params: { filters: this.filters }})
      else
        this.$router.push({path:this.pathToGoBack})
    },
    fetchService() {
      this.loadingService = true
      serviceForm.resetService()
      servicesService.get(this.id).then((service) => {
        this.phases = service.servicePhases
        this.loadingService = false
        serviceForm.updateObject(service)
      })
    },
    openEditPhases() {
      this.editPhasesDialog = true
    },
    movePhase(phase, newPosition) {
      phase.serviceId = this.service.id
      servicePhasesService.move(phase, newPosition).then(() => {
        this.updatePhases()
      })
    },
    updatePhase(phase) {
      phase.serviceId = this.service.id
      servicePhasesService.update(phase).then(() => {
        this.fetchService()
        this.updatePhases()
      })
    },
    createPhase(newPhase) {
      newPhase.serviceId = this.service.id
      servicePhasesService.create(newPhase).then(() => { 
        this.fetchService()
        this.updatePhases()
      })
    },
    removePhase(phase) {
      phase.serviceId = this.service.id
      servicePhasesService.remove(phase).then(() => {
        this.fetchService()
        this.updatePhases()
      })
    },
    updatePhases() {
      serviceForm.updateField('servicePhases', this.phases)
    }
  },
  watch: {
    openDialog(newVal) {
      this.serviceDialog = newVal;
    },
    tab(newVal) {
      try {
        window.history.pushState("Tab", "Tab", this.originalUrl + '?tabName=' + this.servicesTab[newVal].name);
      } catch(err) {
        console.log('try setting the tab name but something goes wrong')
      }
    }
  },
  isLicenseBlocked: {
    bind: 'isLicenseBlocked'
  }
};
</script>